<template>
  <div class="block" style="height: 100vh; background-color: #1f202c">
    <router-view
      class="w-full center justify-center bg-jevi-dark"
      style="height: 100vh"
    />
  </div>
</template>

<script>
const notify = () => import("@/utils/notify.js")
export default {
  data() {
    return {}
  },
  computed: {
    user() {
      return this.$store.getters["auth/getUser"]
    }
  },
  created() {
    if (this.user) {
      this.$router.push("/dashboard")
    }
  },
  methods: {}
}
</script>
